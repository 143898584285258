<template>
  <CCard>
    <CDescriptionList :title="$tc('order', 1)">
      <CDescriptionListItem :label="$t('paymentStatus')">
        <PaymentStatus :status="order.status" />
      </CDescriptionListItem>
      <CDescriptionListItem
        v-for="[key, value, bold] in rows"
        :key="key"
        :no-value-label="$t('noValue')"
        :label="$tc(key, 1)"
        :value="value"
        :highlight="bold || false"
      />
    </CDescriptionList>
  </CCard>
</template>

<script>
import {
  getTaxSumFromInt,
  toDecimalPrice,
  toDecimalPriceInclTax,
} from '@contimo/api/src/utils/integerPriceParser';
import getContainerOpeningTypeString from '@contimo/ui/src/utils/getContainerOpeningTypeString';
import PaymentStatus from '../PaymentStatus.vue';

export default {
  components: {
    PaymentStatus,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    rows() {
      return [
        [
          'products.container.type',
          `${this.$t(
            `products.container.types.${this.order.containerOrder.product.container.type}`,
          )} <br /> ${getContainerOpeningTypeString(
            this.order.containerOrder.product.container,
            this.$i18n,
          )}`,
        ],
        ['merchantWastes.default', this.order.containerOrder.merchantWaste.customName],
        ['size', `${this.$n(this.order.containerOrder.product.container.size)}m&sup3;`],
        ['basePrice', this.parsePrice(this.order.containerOrder.basePrice)],
        ['wastePrice', this.parsePrice(this.order.containerOrder.wastePrice)],
        ['delivery', this.parsePrice(this.order.containerOrder.deliveryPrice)],
        ['durationPrice', this.parsePrice(this.order.containerOrder.durationPrice)],
        ['netRevenue', this.parsePrice(this.order.containerOrder.totalPrice), true],
        ['tax', this.getTax(this.order.containerOrder.totalPrice)],
        ['totalPrice', this.getPriceInclTax(this.order.containerOrder.totalPrice), true],
      ];
    },
  },

  methods: {
    parsePrice(price) {
      return this.$n(toDecimalPrice(price), 'currency');
    },
    getTax(price) {
      return this.$n(toDecimalPrice(getTaxSumFromInt(price, 19)), 'currency');
    },
    getPriceInclTax(price) {
      return this.$n(toDecimalPriceInclTax(price, 19), 'currency');
    },
  },
};
</script>
