<template>
  <CModal
    :show="show"
    variant="fullpagesheet"
    header-class="container no-spacing is-small"
    content-class="bg-gray-50"
    footer-class="hidden"
    left-close-button
    @close="onModalClose"
  >
    <template v-slot:header>
      <template v-if="!loading && currentOrder">
        {{ $t('orderFromDate', { date: parseDate(currentOrder.createdAt) }) }}
      </template>
      <template v-else>
        {{ $t('loading') }}...
      </template>
    </template>
    <template v-slot:content>
      <div v-if="!loading && currentOrder" class="py-4 md:py-8 container is-small">
        <div class="grid grid-cols-9 gap-4 md:gap-6">
          <div class="grid gap-4 md:gap-6 col-span-9 md:col-span-5 md:auto-rows-min">
            <CustomerDetails :customer="currentOrder.customer" />
            <DeliveryDetails :order="currentOrder" />
          </div>
          <div class="grid gap-4 md:gap-6 col-span-9 md:col-span-4 md:auto-rows-min">
            <OrderDetails :order="currentOrder" />
          </div>
        </div>
      </div>
      <div v-else class="h-[450px] flex items-center justify-center">
        <CLoadingSpinner class="w-8 h-8" />
      </div>
    </template>
  </CModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { GET_ORDER } from '@/store/actionTypes';
import { CURRENT_ORDER } from '@/store/gettersTypes';
import { SET_ORDER } from '@/store/mutationsTypes';
import CustomerDetails from './CustomerDetails.vue';
import OrderDetails from './OrderDetails.vue';
import DeliveryDetails from './DeliveryDetails.vue';

export default {
  components: {
    CustomerDetails,
    OrderDetails,
    DeliveryDetails,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapGetters([CURRENT_ORDER]),
    loading() {
      return this.$store.state.orders.singleLoading;
    },
  },

  watch: {
    orderId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getOrder(newVal);
        }
      },
    },
  },

  methods: {
    ...mapActions([GET_ORDER]),
    ...mapMutations([SET_ORDER]),
    onModalClose() {
      this.$emit('close');
      setTimeout(() => {
        this.setOrder(null);
      }, 500);
    },
    parseDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>
