<!-- eslint-disable max-len -->
<template>
  <CCard>
    <CDescriptionList :title="$t('delivery')">
      <template v-slot:right>
        <CButton
          :href="`https://www.google.com/maps/dir/?api=1&origin=${merchantAddressString}&destination=${deliveryAddressString}&travelmode=driving`"
          size="small"
          target="_blank"
        >
          {{ $t('showRoute') }}
        </CButton>
      </template>
      <CDescriptionListItem
        :label="$t('address.deliveryAddress')"
      >
        {{ order.deliveryAddress.street }} {{ order.deliveryAddress.number }}<br>
        {{ order.deliveryAddress.location.postalCode }} {{ order.deliveryAddress.location.city }}
      </CDescriptionListItem>
      <CDescriptionListItem
        v-for="[key, value] in rows"
        :key="key"
        :no-value-label="$t('noValue')"
        :label="$tc(key, 1)"
        :value="value"
      />
    </CDescriptionList>
  </CCard>
</template>

<script>
/* eslint-disable max-len */
import { mapGetters } from 'vuex';
import { THIS_MERCHANT } from '@/store/gettersTypes';

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters([THIS_MERCHANT]),
    rows() {
      return [
        ['distance', `${this.$n(this.order.containerOrder.deliveryDistance / 4)} km`],
        ['deliveryDate', this.parseDate(this.order.containerOrder.deliveryDate)],
        ['pickupDate', this.parseDate(this.order.containerOrder.pickupDate)],
        ['customerNote', this.order.customerNote],
      ];
    },
    merchantAddressString() {
      const { address } = this.thisMerchant;
      return `${address.street}+${address.number},${address.location.postalCode}+${address.location.city}`.replace(
        / +/g,
        '+',
      );
    },
    deliveryAddressString() {
      const { deliveryAddress } = this.order;
      return `${deliveryAddress.street}+${deliveryAddress.number},${deliveryAddress.location.postalCode}+${deliveryAddress.location.city}`.replace(
        / +/g,
        '+',
      );
    },
  },

  methods: {
    parseDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>
