<template>
  <CCard>
    <CDescriptionList :title="$t('customer')">
      <template v-slot:right>
        <CButton
          :href="`mailto:${customer.user.email}`"
          size="small"
          variant="primary"
          target="_blank"
        >
          {{ $t('writeEmail') }}
        </CButton>
        <CButton
          v-if="customer.phone"
          :href="`tel:${customer.phone}`"
          size="small"
          class="ml-3"
        >
          {{ $t('call') }}
        </CButton>
      </template>
      <CDescriptionListItem
        v-for="[key, value] in rows"
        :key="key"
        :no-value-label="$t('noValue')"
        :label="$t(key)"
        :value="value"
      />
    </CDescriptionList>
  </CCard>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    rows() {
      return [
        ['name', `${this.customer.user.firstName} ${this.customer.user.lastName}`],
        ['email', this.customer.user.email],
        ['phone', this.customer.phone],
      ];
    },
  },
};
</script>
